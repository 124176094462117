import React from 'react'
import styled from '@emotion/styled';
import { TreeItem } from '@mui/lab';
import { TreeLabel } from './TreeLabel';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import { Box } from '@mui/system';

const StyledTreeItem = styled(TreeItem)({
    ['& .MuiTreeItem-label']: {
        paddingTop: 6,
        paddingBottom: 6,
        fontSize: "1.25rem!important",
        borderBottom: "1px solid #dcdcdc"
    }
});

export default function TreeItemStyled({ path, name, children, deleteHandler, isLast }) {
    const icon = isLast ? <CameraAltOutlinedIcon sx={{color: "#acacac"}}/> : <FolderOpenIcon sx={{color: "#acacac"}} />
    const labelName = <Box sx={{display: "flex", alignItems: "center"}}><Box sx={{marginBottom: "-0.4rem", marginRight: "0.35rem"}}>{icon}</Box> <Box>{name}</Box></Box>
    const label = <TreeLabel name={labelName} folder={path} deleteHandler={deleteHandler}/>
    return <StyledTreeItem nodeId={path} label={label}>{children && children.map((item, i) => <TreeItemStyled key={i} {...item} deleteHandler={deleteHandler} />)}</StyledTreeItem>
}
