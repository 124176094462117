import { httpsCallable } from "firebase/functions";
import { functions } from '../firebase/firebase-config';

const cloudFunctions = {
    helloWorld: httpsCallable(functions, 'helloWorld'),
    dynamicImagesAuth: httpsCallable(functions, 'dynamicImagesAuth')
}

export const useCloudFunctions = async (name, data) => {
    try {
        const result = await cloudFunctions[name](data)
        return result;
    } catch (err) {
        return {
            code: err.code,
            message: err.message
        }
    }
}
