import { Navigate, useOutlet } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { Toasts } from "../components/Toasts";
import { MenuAppBar } from "../components/MenuAppBar";
import { Upload } from "@mui/icons-material";
import { Download } from "@mui/icons-material";
import { Person } from "@mui/icons-material";
import CropOriginalIcon from '@mui/icons-material/CropOriginal';

export const ProtectedLayout = () => {
  const { user } = useAuth();

  const outlet = useOutlet();
  const sections = [
    {
      label: "Photos", 
      path: "photos", 
      pages: [
        { label: "Upload", path: "upload", icon: Upload },
        { label: "Download", path: "download", icon: Download },
        { label: "Gallery", path: "gallery", icon: CropOriginalIcon },
      ]
    },
    {
      label: "User",
      path: "user",
      pages: [
        { label: "Profile", path: "profile", icon: Person }
      ]
    }
  ];

  if (!user) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <MenuAppBar sections={sections} />
      {outlet}
      <Toasts />
    </>
  );
};