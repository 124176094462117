import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React from 'react'
import { InputHelperText } from './InputHelperText';

export const InputSelect = ({ field, error, handleChange, value }) => {
    const { name, label, autoComplete, message, options, disabled, inputProps, helpText, multiple } = field;

    return (
        <FormControl sx={{mb: 1, mt: 1, width: 1}}>
            <InputLabel id={`${name}-label`}>{label}</InputLabel>
            <Select
                fullWidth
                labelId={`${name}-label`}
                id={name}
                label={label}
                name={name}
                multiple={multiple}
                value={value}
                onChange={handleChange}
                autoComplete={autoComplete}
                error={error}
                disabled={disabled}
                inputProps={inputProps}
            >
                {options && options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
            <InputHelperText error={error} message={message} helpText={helpText} />
        </FormControl>
    )
}
