import React, { useState } from 'react';
import Card from '@mui/material/Card';
import { Button, CardActions, CardContent, Grid, LinearProgress, Link, Typography } from '@mui/material';
import { FolderOpenOutlined, DownloadForOfflineOutlined, ArrowCircleRightOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useZip } from '../hooks/useZip';
import { Link as RouterLink } from 'react-router-dom';
import { DeleteButton } from './DeleteButton';
import { Confirm } from './Confirm';
import { useAuth } from '../hooks/useAuth';

export const TreeLabel = ({ name, folder, deleteHandler }) => {

  const { createZip, loading, setPercent } = useZip();
  const [confirmOpen, setConfirmOpen] = useState(false);

  const handleDelete = async (e) => {
    e.stopPropagation();
    setConfirmOpen(prev => !prev);
  }

  const handleResponse = (response) => {
    response && deleteHandler(folder)
  }

  return (
    <>
      <Grid container sx={{ display: "flex", justifyContent: "space-between" }}>
        <Grid item>{name}</Grid>
        <Grid item>
          <LoadingButton
            color="primary"
            size="small"
            loading={loading}
            loadingPosition="start"
            startIcon={<DownloadForOfflineOutlined />}
            variant="outlined"
            sx={{ py: 0, px: 1, minWidth: "40px" }}
            onClick={async (e) => {
              e.stopPropagation();
              await createZip(folder)
            }}
          >
            Zip
          </LoadingButton>
          <DeleteButton
            handleClick={handleDelete}
            regular={{ py: 0, px: 1, minWidth: "40px", marginLeft: "0.2rem" }}
          />
        </Grid>
      </Grid>
      {confirmOpen && <Confirm open={confirmOpen} setOpen={setConfirmOpen} setResponse={handleResponse} type="file" />}
    </>
  );
}