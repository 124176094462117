import React from 'react';
import { Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export const CloseButton = ({handleClick}) => {
    return (
        <Button
            size="small"
            variant="contained"
            onClick={handleClick}
            sx={{
                position: 'absolute',
                top: 0,
                right: 0,
                p: '0.1rem',
                minWidth: 'fit-content',
                zIndex: "2000",
                borderRadius: 0
            }}>
            <CloseIcon />
        </Button>
    );
}