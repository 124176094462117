import { useState, useMemo } from "react";
import { storage } from "../firebase/firebase-config";
import { ref, uploadBytesResumable, listAll, getDownloadURL, deleteObject } from "firebase/storage";
import { serverTimestamp } from "firebase/firestore";
import Resizer from "react-image-file-resizer";
import { useNotification } from "./useNotification";
import { useDb } from "./useDb";

export const useStorage = () => {
    const { saveImagesToDB, getImagesByPropertyFromDB, deleteImageFromDB, getImageByPropertyFromDB, saveTagsToDB } = useDb();
    const [images, setImages] = useState([]);
    const [folders, setFolders] = useState([]);
    const [loading, setLoading] = useState(false);
    const { setNotification } = useNotification();
    const url = 'https://us-central1-myarchive-nhucal.cloudfunctions.net/dynamicImages/'

    const getFolderStructure = async (parentPath) => {
        const folderRef = ref(storage, parentPath);

        const { prefixes } = await listAll(folderRef);
        const subFolders = [];

        for (const folder of prefixes) {
            if (folder.fullPath) {
                const subFolderStructure = await getFolderStructure(folder.fullPath);
                subFolders.push({
                    parentPath: parentPath,
                    path: folder.fullPath,
                    name: folder.fullPath.split('/').pop(),
                    children: subFolderStructure,
                    isLast: subFolderStructure.length > 0 ? false : true
                })
            };
        }
        return subFolders;
    }

    const getFolders = async (path) => {
        setLoading(true)
        try {
            const folders = await getFolderStructure(path);
            setLoading(false)
            setFolders(folders.reverse())
        } catch (err) {
            console.log(err)
        }
    }

    const getImages = async ({name, value, sort = [], displayLoading = false}) => {
        displayLoading && setLoading(true)
        try {
            const images = await getImagesByPropertyFromDB(value, name)
            displayLoading && setLoading(false);
            setImages(sort.length > 0 ? sortImages(sort, images) : images);
        } catch (err) {
            console.log(err)
            resetImages({
                message: 'Error getting files from database',
                variant: 'error'
            });
        }
    }

    const sortImages = (sort, images) => {
        const sorted = images.sort((a, b) => a.year - b.year || b.month - a.month);
        return sorted
    }

    const uploadImage = ({ toUpload, fileData, index }) => {
        return new Promise(async (resolve, reject) => {
            const { type, mainPath, metaData } = fileData;
            try {
                const resizedImage = await resizeFile(toUpload, type, 3000, 3000)
                const mainTask = uploadBytesResumable(ref(storage, mainPath), resizedImage, metaData);

                mainTask.on('state_changed',
                    (snapshot) => {
                        const progress = Math.ceil((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                        setImages(prev => [...prev].map((p, i) => {
                            if (i === index) p.percent = progress
                            return p
                        }))
                    },
                    (error) => {
                        console.log(error)
                        reject('Encountered an error uploading.');
                    },
                    async () => {
                        try {
                            const mainUrl = await getDownloadURL(ref(storage, mainPath))
                            fileData.mainUrl = mainUrl;
                            resolve(fileData);
                        } catch (error) {
                            console.log(error)
                            reject('Encountered an error creating file information.');
                        }
                    }
                );
            } catch (error) {
                console.log(error)
                reject('Encountered an error with resizing image.');
            }
        });
    }

    const uploadImages = async ({ family, eventName, tags, uid }) => {
        if (images.length === 0) {
            return false;
        } else {
            const tagsArray = tags.split(',').filter(f => f !== '');
            let rollback = [];
            await saveTagsToDB(tagsArray);
            setLoading(prev => !prev)
            try {
                const uploaded = await Promise.all(images.map(async (img, index) => {
                    const toUpload = createFileData(img, eventName, family, tagsArray, uid, index);
                    rollback.push(toUpload.fileData);
                    const upload = await uploadImage(toUpload);
                    return upload;
                }))
                attemptToSaveImagesToDB(uploaded)
            } catch (error) {
                console.log(error)
                rollBackImages(rollback, error)
            }
        }
    }

    const attemptToSaveImagesToDB = async (uploaded) => {
        try {
            await saveImagesToDB(uploaded)
            resetImages({
                message: 'Successfully uploaded images. Feel free to add more.',
                variant: 'success'
            });
        } catch (error) {
            console.log(error)
            rollBackImages(uploaded, error)
        }
    }

    const rollBackImages = async (rollback, message) => {
        try {
            await Promise.all(rollback.map(async (img) => {
                return await deleteImage(null, null, img.mainPath, null);
            }))
            resetImages({
                message: `${message} Images were rolled back, no changes were made`,
                variant: 'error'
            });
        } catch (error) {
            console.log(error)
            resetImages({
                message: 'Could not rollback changes, contact admin.',
                variant: 'error'
            });
        }
    }

    const resetImages = (message) => {
        setImages([])
        setLoading(prev => !prev);
        setNotification(message);
    }

    const createFileData = (file, event, family, tags, uid, index) => {
        const type = file.type;
        const toUpload = file.toUpload;
        const lastModified = file.toUpload.lastModified;
        const ext = file.toUpload.name.split('.').pop();
        const name = new Date(lastModified).toLocaleDateString('en-GB').split('/').reverse().join('') + '_' + parseInt(Math.random() * 1000000000, 10);
        const eventParts = event.split(" ");
        const parentPath = `download/${eventParts[0]}/${family}/${eventParts[1]}${eventParts[2]}`;
        const fileName = type === 'image' ? `${name}.jpeg` : `${name}.${ext}`;
        const mainPath = `${parentPath}/${fileName}`;
        const fileData = {
            uid: uid,
            parentPath: parentPath,
            mainPath: mainPath,
            mainUrl: null,
            tags: tags,
            fileName: fileName,
            name: name,
            ext: ext,
            year: eventParts[0],
            month: eventParts[2],
            family: family,
            metaData: {
                customMetadata: {
                    family: family,
                    tags: tags.join(", "),
                    event: event,
                    timeCreated: String(lastModified),
                    updated: String(lastModified)
                }
            },
            type: type,
            contentType: file.toUpload.type,
            timeAdded: serverTimestamp(),
        }
        return { toUpload: toUpload, fileData: fileData, index: index }
    }

    const resizeFile = (file, type, height, width) => {
        return new Promise((resolve) => {
            if (type === 'image') {
                Resizer.imageFileResizer(file, height, width, "JPEG", 70, 0, (uri) => {
                    resolve(uri);
                }, "file", height, width);
            }
            if (type === 'video') {
                resolve(file);
            }
        });
    }

    const removeImage = (index) => {
        setImages(prev => prev.filter((_, i) => i !== index))
    }

    const deleteImage = async (id, index, mainPath) => {
        setLoading(true);
        if (index !== null) {
            removeImage(index);
        }
        if (mainPath) {
            try {
                const imageRef = ref(storage, mainPath);
                await deleteObject(imageRef)
            } catch (error) {
                console.log(error)
            }
        }
        if (id) {
            try {
                await deleteImageFromDB(id)
            } catch (error) {
                console.log(error)
            }
        }
        setLoading(false);
        setNotification({ message: 'Success deleting image', variant: 'success' })
        return 'deleted image'
    }

    const deleteFolderContents = async (folder) => {
        const directoryContentsRef = ref(storage, folder);
        const directoryContents = await listAll(directoryContentsRef)

        for (const file of directoryContents.items) {
            const fileRef = ref(storage, file.fullPath);
            const id = await getImageByPropertyFromDB('mainPath', file.fullPath);
            if (id !== null) {
                try {
                    await deleteImageFromDB(id)
                } catch (error) {
                    console.log(error)
                }
            }
            try {
                await deleteObject(fileRef)
            } catch (error) {
                console.log(error)
            }
        }
        
        for (const folder of directoryContents.prefixes) {
            await deleteFolderContents(folder.fullPath);
        };
    }

    const deleteFolder = async (path, index) => {
        setLoading(true);
        try {
            await deleteFolderContents(path);
            if (!index) {
                await getFolders('download');
                setImages([]);
            } else {
                setLoading(true);
                setFolders(prev => prev.filter((_, i) => i !== index))
            }
            setNotification({ message: 'Success deleting folder and contents', variant: 'success' })
        } catch (error) {
            console.log(error)
            setLoading(false);
            setNotification({ message: 'error deleting folder', variant: 'error' })
        }
    }

    return useMemo(
        () => ({
            loading,
            images,
            folders,
            url,
            getImages,
            setImages,
            uploadImages,
            removeImage,
            deleteImage,
            getFolders,
            setFolders,
            deleteFolder
        }),
        [images, folders, loading, url]
    )
};