import { Route, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import { ProtectedLayout } from "./layouts/ProtectedLayout";
import { HomeLayout } from "./layouts/HomeLayout";
import { RegisterPage } from "./pages/Register";
import { LoginPage } from "./pages/Login";
import { ProfilePage } from "./pages/Profile";
import { ResetPage } from "./pages/Reset";
import { NoMatch } from "./pages/NoMatch";
import { UploadPage } from "./pages/Upload";
import { ErrorPage } from "./pages/Error";
import { TreePage } from "./pages/Tree";
import { GalleryPage } from "./pages/Gallery";

export const App = () => {

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route element={<HomeLayout />} errorElement={<ErrorPage />}>
          <Route path="/" element={<LoginPage />} />
          <Route path="/register/:access" element={<RegisterPage />} />
          <Route path="/reset" element={<ResetPage />} />
        </Route>
        <Route path="/photos" element={<ProtectedLayout />} errorElement={<ErrorPage />}>
          <Route path="upload" element={<UploadPage />} />
          <Route path="download" element={<TreePage />} />
          <Route path="gallery" element={<GalleryPage />} />
        </Route>
        <Route path="/user" element={<ProtectedLayout />} errorElement={<ErrorPage />}>
          <Route path="profile" element={<ProfilePage />} />
        </Route>
        <Route path="*" element={<NoMatch />} />
      </>
    )
  )

  return router
}