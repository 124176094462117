import React from 'react';
import { LoadingButton } from '@mui/lab';

export const LoadingActionButton = ({loading, type, text, icon}) => {
    return (
        <LoadingButton
          type={type}
          fullWidth
          size='large'
          loadingPosition="start"
          startIcon={icon}
          loading={loading}
          variant="contained"
          sx={{ mt: 2, mb: 2 }}
        >
          {text}
        </LoadingButton>
    );
}