import React, { forwardRef } from 'react'
import { Dialog, Slide } from '@mui/material'
import { CloseButton } from './CloseButton';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const ModalDialog = ({ children, open, setOpen }) => {
    const handleClose = () => setOpen(false);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            keepMounted
            fullWidth={true}
            maxWidth="md"
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column"
            }}
        >
            <CloseButton handleClick={handleClose}/>
            {children}
        </Dialog>
    )
}