import React from 'react';
import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import ImageSkeleton from './ImageSkeleton';

export const ImageGridSkeleton = ({ wide }) => {
    return (
        <>
            <Grid xs={wide ? 6 : 12} sm={wide ? 4 : 6} md={wide ? 3 : 4} lg={wide ? 2 : 3} item>
                <Box sx={{ position: 'relative', borderRadius: "0.2rem", overflow: "hidden" }}>
                    <ImageSkeleton />
                </Box>
            </Grid>
        </>
    );
}