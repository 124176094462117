import React from 'react'
import { Skeleton, Box } from '@mui/material'

export default function ImageSkeleton() {
    return (
        <Box
            sx={{
                height: 0,
                overflow: "hidden",
                paddingTop: "100%",
                position: "relative"
            }}>
            <Skeleton
                variant='rectangle'
                animation="wave"
                sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%"
                }} />
        </Box>
    )
}