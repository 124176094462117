import { useAuth } from "../hooks/useAuth";
import { BasicPage } from "../components/BasicPage";
import { InputForm } from "../components/InputForm";
import { Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import LockResetIcon from '@mui/icons-material/LockReset';

export const ResetPage = () => {
  const { reset, loading } = useAuth();

  const fields = [
    {
      name: 'email',
      label: 'Email Address',
      type: 'email',
      autoComplete: 'email',
      autoFocus: false,
      message: 'Please enter a valid email',
      validation: [{ required: true, email: true }]
    }
  ]

  return (
    <BasicPage title="Reset Password" icon={<LockResetIcon />}>
      <InputForm fields={fields} action={reset} buttonText="Reset Password" loading={loading}/>
      <Link to="/" component={RouterLink} underline="none" typography="body2">Back to login</Link>
    </BasicPage>
  );
};
