import { IconButton } from '@mui/material'
import React from 'react'
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';

export const ImageModal = ({ image, images, index, setNext }) => {
    const totalImages = images.length;
    const prev = index - 1 === -1 ? totalImages - 1 : index - 1;
    const next = index + 1 >= totalImages ? 0 : index + 1;

    const handleClick = (index) => {
        setNext(index)
    }

    return (
        <div style={{ padding: "0.3rem 0.3rem 0.05rem 0.3rem", width: "100%", height: "100%", boxSizing: "border-box", position: "relative", overflow: 'hidden' }}>
            {image && image.type === 'image' &&
                <img
                    src={image.mainUrl}
                    alt={image.metaData.customMetadata.event}
                    style={{ maxWidth: "100%", maxHeight: "80vh" }}
                />
            }
            {image && image.type === 'video' &&
                <video controls style={{ maxWidth: "100%", maxHeight: "80vh" }}>
                    <source src={image.mainUrl} type={image.contentType} />
                    Your browser does not support the video tag.
                </video>
            }
            <IconButton onClick={() => handleClick(prev)} aria-label="delete" size="large" sx={{ position: "absolute", top: "50px", bottom: "50px", left: "0", color: "white", p: "1.5rem" }}>
                <KeyboardArrowLeftOutlinedIcon fontSize="inherit" />
            </IconButton>
            <IconButton onClick={() => handleClick(next)} aria-label="delete" size="large" sx={{ position: "absolute", top: "50px", bottom: "50px", right: "0", color: "white", p: "1.5rem" }}>
                <KeyboardArrowRightOutlinedIcon fontSize="inherit" />
            </IconButton>
        </div>
    )
}
