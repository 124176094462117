import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import { MenuDrawerList } from './MenuDrawerList';

export const MenuDrawer = ({ sections, openDrawer, setOpenDrawer }) => {

    const toggleDrawer = (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setOpenDrawer(prev => !prev);
    };

    return (
        <Drawer
            anchor="left"
            open={openDrawer}
            onClose={(e) => toggleDrawer(e)}
        >
            <MenuDrawerList sections={sections} setOpenDrawer={setOpenDrawer} />
        </Drawer>
    );
}