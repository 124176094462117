import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { AuthProvider } from './hooks/useAuth';
import { NotificationProvider } from "./hooks/useNotification";
import { App } from "./App";
import { RouterProvider } from "react-router-dom";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

const router = App();

const theme = createTheme({
  palette: {
    primary: { main: "#6e7e85" }
  }
});

root.render(
  <StrictMode>
    <NotificationProvider>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <RouterProvider router={router} />
        </ThemeProvider>
      </AuthProvider>
    </NotificationProvider>
  </StrictMode>
);
