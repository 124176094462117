import { Navigate, useOutlet } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { Toasts } from "../components/Toasts";
import { MenuAppBar } from "../components/MenuAppBar";

export const HomeLayout = () => {
  const { user } = useAuth();
  const outlet = useOutlet();

  if (user) {
    if (user.new || !user.displayName) {
      return <Navigate to="/user/profile" />
    }
    return <Navigate to="/photos/upload" />;
  }

  return (
    <>
      <MenuAppBar />
      {outlet}
      <Toasts />
    </>
  );
};
