import * as React from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { CardContent, Grid, LinearProgress } from '@mui/material';
import { DeleteButton } from './DeleteButton';

export const UploadCard = ({ image, index, handleRemove, loading }) => {
  return (
    <Grid item xs={6} sm={4} md={3}>
      <Card sx={{ width: '100%', position: 'relative' }}>
        <CardMedia
          component="img"
          height="200"
          image={image.localUrl}
          alt="green iguana"
        />
        {!loading && <DeleteButton handleClick={() => handleRemove(index)} />}
        <CardContent sx={{ padding: '8px', paddingBottom: '8px!important' }}>
          <LinearProgress variant="determinate" color="success" value={image.percent} sx={{ height: 20 }} />
        </CardContent>
      </Card>
    </Grid>
  );
}