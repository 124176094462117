import React, { useState, useEffect } from 'react';
import { Grid, ImageListItemBar } from '@mui/material';
import { Confirm } from './Confirm';
import { DeleteButton } from './DeleteButton';
import { Box } from '@mui/system';
import { useCloudFunctions } from '../hooks/useCloudFunctions';
import ImageSkeleton from './ImageSkeleton';
import { useDb } from '../hooks/useDb';
import placeholder from '../assets/img/placeholder.jpg';

export const ImageGridItem = ({ image, index, handleRemove, handleModalOpen, tags, wide }) => {
    const { updateImageInDB } = useDb();
    const { mainPath, id, metaData, dataPath, type } = image;
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [dataImage, setDataImage] = useState(null)

    useEffect(() => {
        const runFunction = async () => {
            if (type === 'video') {
                setDataImage(placeholder);
            } else {
                const result = await useCloudFunctions("dynamicImagesAuth", { url: mainPath, w: 400, h: 400, q: 70 })
                const base64image = `data:image/jpeg;base64,${result.data.data}`
                setDataImage(base64image)
                await updateImageInDB(id, { dataPath: base64image })
            }
        }
        if (!dataPath) {
            runFunction()
        } else {
            setDataImage(dataPath)
        }
    }, [image]);


    const handleOpen = () => handleModalOpen(index);

    const handleDelete = async (e) => {
        e.stopPropagation();
        setConfirmOpen(prev => !prev);
    }

    const handleResponse = (response) => {
        if (response) {
            handleRemove(id, index, mainPath)
        }
    }

    return (
        <>
            <Grid xs={wide ? 6 : 12} sm={wide ? 4 : 6} md={wide ? 3 : 4} lg={wide ? 2 : 3} item onClick={handleOpen}>
                <Box sx={{ position: 'relative', cursor: 'pointer', borderRadius: "0.2rem", overflow: "hidden" }}>
                    {dataImage ?
                        <img
                            src={dataImage}
                            alt={metaData.customMetadata.event}
                            loading="lazy"
                            style={{ width: "100%", marginBottom: "-4px" }}
                        />
                        :
                        <ImageSkeleton />
                    }
                    {tags && image.tags && image.tags.length > 0 &&
                        <ImageListItemBar
                            sx={{
                                background:
                                    'linear-gradient(to top, rgba(0,0,0,0.7) 0%, ' +
                                    'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                                textAlign: 'right'
                            }}
                            subtitle={image.tags.join(', ')}
                            position="bottom"
                        />
                    }
                    {handleRemove && <DeleteButton handleClick={handleDelete} />}
                </Box>
            </Grid>
            {confirmOpen && <Confirm open={confirmOpen} setOpen={setConfirmOpen} setResponse={handleResponse} type="file" />}
        </>
    );
}