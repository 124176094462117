import React from 'react';
import { Button } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

export const DeleteButton = ({handleClick, regular = null}) => {
    const top = {
        position: 'absolute',
        top: 0,
        right: 0,
        p: '0.1rem',
        minWidth: 'fit-content',
        borderRadius: 0
    }

    return (
        <Button
            size="small"
            variant={regular === null ? 'contained' : 'outlined'}
            color="error"
            onClick={handleClick}
            sx={regular === null ? top : regular}>
            <DeleteForeverIcon />
        </Button>
    );
}