import React, { useState, useEffect } from "react";
import { SendOutlined } from "@mui/icons-material";
import { Box } from "@mui/material";
import { LoadingActionButton } from "./LoadingActionButton";
import { InputField } from "./InputField";

export const InputForm = ({ fields, action, customAction, additionalAction, buttonText, loading }) => {
    const [formState, setFormState] = useState([]);
    const [validate, setValidate] = useState(false);
    const [formData, setFormData] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormData(createFormData(e.currentTarget));
    };

    useEffect(() => {
        if (formData !== null) {
            setValidate(true)
        }
    }, [formData]);

    useEffect(() => {
        const trySubmit = async () => {
            const valid = formState.findIndex(f => f.error === true) === -1 && formState.length !== 0
            if (valid) {
                setFormState([]);
                await action(formData);
                if (additionalAction) {
                   await additionalAction(formData)
                }
            }
        }
        if (validate) {
            trySubmit()
        }
        return () => {
            setValidate(false)
            setFormData(null)
        };
    }, [validate])

    const createFormData = (form) => {
        const data = new FormData(form);
        const fieldValues = {}
        fields && fields.forEach(f => {
            fieldValues[f.name] = data.get(f.name)
        });
        return fieldValues;
    }

    return (
        <Box
            component="form"
            onSubmit={customAction ? customAction : handleSubmit}
            noValidate
            autoComplete="on"
            maxWidth="sm"
            mt={5}
        >
            {fields && fields.map((f, i) => <InputField key={i} field={f} validate={validate} formState={formState} setFormState={setFormState} />)}
            <LoadingActionButton type="submit" loading={loading} text={buttonText} icon={<SendOutlined />} />
        </Box>
    );
};
