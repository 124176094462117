import React from 'react'
import { TextField, FormControl, Chip, Autocomplete } from '@mui/material'
import { InputHelperText } from './InputHelperText';

export const InputAutoComplete = ({ field, error, handleChange, value }) => {
    const { name, label, message, options, disabled, helpText } = field;
    return (
        <FormControl sx={{ mb: 1, mt: 1, width: 1 }}>
            <Autocomplete
                autoComplete={true}
                multiple
                options={options.map((option) => option)}
                freeSolo
                value={value}
                onChange={(e, val, reason) => handleChange(e, val, reason)}
                onInputChange={(e, val, reason) => handleChange(e, val, reason)}
                disabled={disabled}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip variant="filled" label={option} {...getTagProps({ index })} />
                    ))
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        label={label}
                        error={error}
                    />
                )}
            />
            <InputHelperText error={error} message={message} helpText={helpText} />
            <input type="hidden" name={name} value={value} />
        </FormControl>
    )
}