import React, { useState, useEffect } from 'react';
import { InputTextField } from './InputTextField';
import { InputSelect } from './InputSelect';
import { InputAutoComplete } from './InputAutoComplete';
import { InputHidden } from './InputHidden';
import { InputFiles } from './InputFiles';
import { checkIsSet } from '../utils/common';

export const InputField = ({ field, validate, formState, setFormState }) => {
    const { template, current, type, currentCount, validation, onChangeFile } = field;
    const [error, setError] = useState(false);
    const [value, setValue] = useState(current ? current : '');
    const [first, setFirst] = useState(checkIsSet(current) ? false : true);
        
    const checkRequired = (val) => {
        return val.length > 1 ? false : true;
    }

    const checkMinLength = (val, length, useCount = false) => {
        const check = !useCount ? val.length : currentCount
        return check >= length ? false : true;
    }

    const checkMaxLength = (val, length, useCount = false) => {
        const check = !useCount ? val.length : currentCount
        return check < length ? false : true;
    }

    const checkEmail = (val) => {
        return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(val) ? false : true
    }

    const checkSelect = (val, check) => {
        return val !== check ? false : true;
    }

    const checkFilesCount = (count, min, max) => {
        return count >= min && count <= max ? false : true;
    }

    useEffect(() => {
        if (formState.length === 0) {
            setFirst(true);
        }
    }, [formState])

    useEffect(() => {
        if (first && !validate) {
            return
        }
        let noValidateFlag = false;
        let error = false;
        if (validation) {
            const validations = validation.map(v => {
                let status = [];
                if (v.noValidation) {
                    noValidateFlag = true;
                    return
                }
                if (v.required) {
                    status.push(checkRequired(value));
                }
                if (v.minLength) {
                    status.push(checkMinLength(value, v.minLength))
                }
                if (v.email) {
                    status.push(checkEmail(value))
                }
                if (v.select) {
                    status.push(checkSelect(value, v.select.value))
                }
                if (v.maxLength) {
                    status.push(checkMaxLength(value, v.maxLength))
                }
                if (v.filesCount) {
                    status.push(checkFilesCount(currentCount, v.filesCount.min, v.filesCount.max))
                }
                return status.includes(true);
            })
            error = validations.includes(true)
        }
        if (noValidateFlag === false) {
            setError(error);
            const fieldObject = { error: error, field: field.name }
            const formStateCopy = [...formState]
            const exists = formStateCopy.findIndex(fs => fs.field == field.name);
            if (exists === -1) {
                formStateCopy.push(fieldObject)
            } else {
                formStateCopy[exists] = fieldObject
            }
            setFormState(formStateCopy)
        }
    }, [value, currentCount, validate]);

    const handleChange = (e, val = null, reason = null) => {
        first && setFirst(false)
        if (type === 'file') {
            onChangeFile(e)
            setValue('')
        } else if (type === 'autocomplete') {
            if (reason !== 'input' && Array.isArray(val)) {
                setValue(val)
            }
        } else {
            setValue(e.target.value)
        }
    }

    switch (template) {
        case 'select':
            return(<InputSelect field={field} value={value} error={error} handleChange={handleChange} />)
        case 'autocomplete':
            return(<InputAutoComplete field={field} value={value} error={error} handleChange={handleChange} />)
        case 'files':
            return(<InputFiles field={field} value={value} error={error} handleChange={handleChange} />)
        case 'hidden':
            return(<InputHidden field={field} value={value} />)
        default:
            return(<InputTextField field={field} value={value} error={error} handleChange={handleChange} />)
    }
}