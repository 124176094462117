import { createContext, useEffect, useContext, useMemo, useState } from "react";
import { auth } from "../firebase/firebase-config";
import { browserSessionPersistence, setPersistence, onAuthStateChanged, signOut, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail, updateCurrentUser, updateEmail } from "firebase/auth";
import { useNotification } from "./useNotification";
import { useDb } from "./useDb";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const { setNotification } = useNotification();
  const { getUserInDB, createUserInDB, updateUserInDB, getStatusesByUser } = useDb();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async user => {
      if (user) {
        try {
          const dbUser = await getUserInDB(user.uid);
          setUser(dbUser);
        } catch {
          setNotification({ message: 'Error connecting to DB, please refresh page', variant: 'error' })
          setUser(null)
        }
      } else {
        setUser(null);
      }
    });
    return () => unsubscribe();
  }, []);

  const checkForm = (formState) => {
    if (!formState) {
      setNotification({ message: 'Must fill out form', variant: 'error' })
      return false
    }
    return true
  }

  const login = async (data) => {
    setLoading(prev => !prev)
    setPersistence(auth, browserSessionPersistence)
      .then(() => {
        signInWithEmailAndPassword(auth, data.email, data.password)
          .then((userCredential) => {
            setLoading(prev => !prev)
            setNotification({ message: 'Success, logged in.', variant: 'success' })
          })
          .catch((error) => {
            setLoading(prev => !prev)
            setNotification(createErrorMessage(error.code))
          });
      })
      .catch((error) => {
        setLoading(prev => !prev)
        setNotification(createErrorMessage(error.code))
      });

  };

  const signup = (data) => {
    setLoading(prev => !prev)
    setPersistence(auth, browserSessionPersistence)
      .then(() => {
        createUserWithEmailAndPassword(auth, data.email, data.password)
          .then((userCredential) => {
            const uid = userCredential.user.uid;
            const email = userCredential.user.email;

            createUserInDB(uid, email)
              .then(() => {
                setLoading(prev => !prev)
                setNotification({ message: 'Success, logged in with new user.', variant: 'success' })
              })
              .catch((error) => {
                setLoading(prev => !prev)
                setNotification(createErrorMessage(error.code))
              });
          })
          .catch((error) => {
            setLoading(prev => !prev)
            setNotification(createErrorMessage(error.code))
          });
      })
      .catch((error) => {
        setLoading(prev => !prev)
        setNotification(createErrorMessage(error.code))
      });
  };

  const update = (userData) => {
    setLoading(prev => !prev)
    const currentUser = auth.currentUser;
    if (currentUser === null) {
      setLoading(prev => !prev)
      setNotification({ message: 'Did not load profile correctly, please refresh page', variant: 'error' })
      return
    }
    const currentEmail = currentUser.email;
    const currentUid = currentUser.uid;
    if (userData.email !== currentEmail) {
      updateEmail(currentUser, userData.email)
        .then(() => {
          updateUser(userData, currentUid)
        })
        .catch((error) => {
          setLoading(prev => !prev)
          setNotification(createErrorMessage(error.code))
        })
    } else {
      updateUser(userData, currentUid)
    }
  }

  const updateUser = async (userData, currentUid) => {
    updateUserInDB(currentUid, userData)
      .then(() => {
        setLoading(prev => !prev)
        setUser(userData)
        setNotification({ message: 'Success, updated user data.', variant: 'success' })
      })
      .catch((error) => {
        console.log(error);
        setLoading(prev => !prev)
        setNotification(createErrorMessage(error.code))
      })
  }

  const reset = async (data) => {
    setLoading(prev => !prev)
    sendPasswordResetEmail(auth, data.email)
      .then(() => {
        setNotification({ message: 'Sent email to associated address. Please follow those instructions', variant: 'success' })
        setLoading(prev => !prev)
      })
      .catch((error) => {
        setLoading(prev => !prev)
        setNotification(createErrorMessage(error.code))
      });
  };

  const logout = () => {
    signOut(auth)
      .then(() => {
        setUser(null);
        setNotification({ message: 'Successfully logged out', variant: 'success' })
      })
      .catch((error) => {
        setNotification(createErrorMessage(error.code))
      })

  };

  const createErrorMessage = (error) => {
    let message = {
      message: 'problem with request',
      variant: 'error'
    };
    if (error === "auth/invalid-email") {
      message.message = "no user exists"
    }
    if (error === "auth/wrong-password") {
      message.message = "password is incorrect"
    }
    if (error === "auth/app-not-authorized") {
      message.message = "not authorized"
    }
    if (error === "auth/requires-recent-login") {
      message.message = "requires recent login"
    }
    if (error === "auth/user-not-found") {
      message.message = "user not found"
    }
    if (error === "auth/email-already-in-use") {
      message.message = "user already exists"
    }
    if (error === "auth/weak-password") {
      message.message = "weak password, minimum 6 characters"
    }
    if (error === "auth/too-many-requests") {
      message.message = "too many requests, try back later"
    }
    if (error === "auth/insufficient-permission") {
      message.message = "insufficient permissions contact administrator"
    }
    return message
  }

  const value = useMemo(
    () => ({
      user,
      loading,
      login,
      logout,
      signup,
      reset,
      update,
      checkForm
    }),
    [user, loading]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
