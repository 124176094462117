import React from 'react'
import { FormControl, TextField } from '@mui/material'
import { InputHelperText } from './InputHelperText';

export const InputTextField = ({ field, error, handleChange, value }) => {
    const { name, label, type, autoComplete, autoFocus, message, disabled, inputProps, helpText } = field;

    return (
        <FormControl sx={{ mb: 1, mt: 1, width: 1 }}>
            <TextField
                id={name}
                label={label}
                name={name}
                type={type}
                value={value}
                onChange={handleChange}
                onSelect={handleChange}
                autoComplete={autoComplete}
                autoFocus={autoFocus}
                error={error}
                disabled={disabled}
                inputProps={inputProps}
            />
            <InputHelperText error={error} message={message} helpText={helpText} />
        </FormControl>
    )
}
