export const useEvents = () => {

    const users = [
        {
            value: 'Jennifer',
            label: 'The Lind Family',
        },
        {
            value: 'Nick',
            label: 'Nick and Zelda',
        }, 
        {
            value: 'Steven',
            label: 'The Hucal Family - Steve',
        }, 
        {
            value: 'Danielle',
            label: 'The Melynk Family',
        }, 
        {
            value: 'Madison',
            label: 'Madison and Ethan',
        },
        {
            value: 'Mom',
            label: 'Mom'
        },
        {
            value: 'Everyone',
            label: 'The Entire Family',
        },
        {
            value: 'Other',
            label: 'Misc',
        }
    ]

    const createEvents = (series, months) => {
        const final = []
        series.forEach(ser => {
            months.forEach(m => {
                final.push({
                    value: ser + " " + m,
                    label: ser + " " + m,
                })
            })
        })
        return final;
    }

    const createYears = (stopYear) => {
        let years = [];
        for (let i = new Date().getFullYear(); i >= stopYear; i--) {
            years.push(i);
        }
        return years
    }

    const createMonths = () => {
        return Array.from({ length: 12 }, (e, i) => {
            const leadingNumber = String(i + 1).padStart(2, '0') + '. ';
            return leadingNumber + new Date(null, i + 1, null).toLocaleDateString("en", { month: "long" });
        }).reverse()
    }

    const currentEvent = () => {
        const currentDate = new Date();
        const leadingNumber = String(currentDate.getMonth() + 1).padStart(2, '0') + '. ';
        return currentDate.getFullYear() + " " + leadingNumber + currentDate.toLocaleString('default', { month: 'long' });
    }

    const events = createEvents(createYears(2015), createMonths());

    return { events, currentEvent, users };
};
