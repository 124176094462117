import { useAuth } from "../hooks/useAuth";
import { Navigate, useParams } from "react-router-dom";
import { BasicPage } from "../components/BasicPage";
import { useNotification } from "../hooks/useNotification";
import { InputForm } from "../components/InputForm";
import { Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';

export const RegisterPage = () => {
  const { signup, loading } = useAuth();
  const { setNotification } = useNotification();
  const { access } = useParams(access);
  const key = process.env.REACT_APP_ACCESS_KEY;

  if (key !== access) {
    setNotification({ message: 'access denied', variant: "error" })
    return <Navigate to="/" replace />;
  }

  const fields = [
    {
      name: 'email',
      label: 'Email Address',
      type: 'email',
      autoComplete: 'email',
      autoFocus: false,
      message: 'Please enter a valid email',
      validation: [{ required: true, email: true }]
    },
    {
      name: 'password',
      label: 'Password',
      type: 'password',
      autoComplete: 'new-password',
      autoFocus: false,
      message: 'Please enter a password, must be 8 characters or longer',
      validation: [{ required: true, minLength: 8 }]
    }
  ]

  return (
    <BasicPage title="Register" icon={<PersonAddAltIcon />}>
      <InputForm fields={fields} action={signup} buttonText="Register" loading={loading}/>
      <Link to="/" component={RouterLink} underline="none" typography="body2">Back to login</Link>
    </BasicPage>
  );
};
