import React from 'react'
import { Error } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import { Box, Container } from '@mui/system';
import { Link, useRouteError } from 'react-router-dom';
import { MenuAppBar } from "../components/MenuAppBar";
import { BasicPage } from '../components/BasicPage';

export const ErrorPage = () => {
    let error = useRouteError();
    console.error(error);
    return (
        <div>
            <MenuAppBar />
            <BasicPage title="Error" icon={<Error />} />
            <Container component="main" maxWidth="xs">
                <Box
                    sx={{
                        marginTop: 4,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center"
                    }}
                >
                    <Typography component="h1" variant="h5" sx={{ mb: 4 }}>
                        {error && error.message}
                    </Typography>
                    <Button component={Link} to="/">Go to the home page</Button>
                </Box>
            </Container>
        </div>
    );
}