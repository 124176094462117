import { useAuth } from "../hooks/useAuth";
import { BasicPage } from "../components/BasicPage";
import { Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { InputForm } from "../components/InputForm";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

export const LoginPage = () => {
  const { login, loading } = useAuth();

  const fields = [
    {
      name: 'email',
      label: 'Email Address',
      type: 'email',
      autoComplete: 'email',
      autoFocus: false,
      message: 'Please enter a valid email',
      validation: [{ required: true, email: true }]
    },
    {
      name: 'password',
      label: 'Password',
      type: 'password',
      autoComplete: 'current-password',
      autoFocus: false,
      message: 'Please enter a password, must be 8 characters or longer',
      validation: [{ required: true, minLength: 8 }]
    }
  ]

  return (
    <BasicPage title="Login" icon={<LockOutlinedIcon />}>
      <InputForm fields={fields} action={login} buttonText="Log In" loading={loading} />
      <Link to="/reset" component={RouterLink} underline="none" typography="body2">Reset Password</Link>
    </BasicPage>
  );
};
