import { useState } from "react";
import { BasicPage } from "../components/BasicPage";
import { useAuth } from "../hooks/useAuth";
import { Person } from "@mui/icons-material";
import { useEvents } from "../hooks/useEvents";
import { InputForm } from "../components/InputForm";
import { ModalDialog } from "../components/ModalDialog";
import { Typography, DialogContent } from "@mui/material";

export const ProfilePage = () => {
  const { user, update, loading } = useAuth();
  const { users } = useEvents();
  const [modalOpen, setModalOpen] = useState(user.new);

  const fields = [
    {
      name: 'email',
      label: 'Email Address',
      type: 'email',
      autoComplete: 'email',
      autoFocus: false,
      current: user.email,
      message: 'Please enter a valid email',
      validation: [{ required: true, email: true }]
    },
    {
      name: 'displayName',
      label: 'Display Name',
      type: 'text',
      current: user.displayName ? user.displayName : '',
      message: 'Please enter a display name of at least 3 characters',
      validation: [{ required: true, minLength: 3 }]
    },
    {
      name: 'family',
      type: 'text',
      label: 'Choose Family',
      current: user.family ? user.family : '',
      options: users,
      message: 'Please select a default family',
      validation: [{ select: {value: ''} }],
      template: 'select'
    }
  ]

  return <BasicPage title="Profile Page" icon={<Person />} >
    <InputForm fields={fields} action={update} buttonText="Save" loading={loading} />
    <ModalDialog open={modalOpen} setOpen={setModalOpen}>
      <DialogContent>
        <Typography variant="h4" mb={2}>Hello User</Typography>
        <Typography mb={2}>Please fill out your user details. Choose a display name and a default family for when you upload photos. You will be able to select a different family if you so choose on the upload screen, this setting just preloads it for you.</Typography>
        <Typography>
          You can update these details anytime by revisting the profile page. You can view any other page options by choosing from the menu on the navigation bar.
        </Typography>
      </DialogContent>
    </ModalDialog>
  </BasicPage>;
};
