import React, { useEffect, useState } from "react";
import { BasicPage } from "../components/BasicPage";
import { useStorage } from "../hooks/useStorage";
import { Button, Grid, Skeleton, Typography } from "@mui/material";
import { ModalDialog } from "../components/ModalDialog";
import { ImageModal } from "../components/ImageModal";
import { ImageGridItem } from "../components/ImageGridItem";
import { Box } from "@mui/system";
import { Download } from "@mui/icons-material";
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import TreeItemStyled from "../components/TreeItemStyled";


export const TreePage = () => {
    const { getFolders, getImages, deleteImage, setImages, deleteFolder, loading, folders, images, url } = useStorage();

    const [expanded, setExpanded] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    useEffect(() => {
        getFolders('download')
    }, []);

    useEffect(() => {
        setModalOpen(selectedImage === null ? false : true)
    }, [selectedImage])

    const handleFocus = (_, id) => getImages({name: "parentPath", value: id})

    const handleRemove = async (id, index, fullPath, thumbPath) => {
        return await deleteImage(id, index, fullPath, thumbPath);
    }

    const handleModalOpen = (index) => {
        setSelectedImage({ image: images[index], index: index })
    }

    const handleModalClose = () => {
        setSelectedImage(null)
    }

    const handleExpandClick = () => {
        setExpanded((prev) => {
            prev.length !== 0 && setImages([])
            return prev.length === 0 ? generateIds([...folders]) : []
        });
    };

    const handleToggle = (_, nodeIds) => {
        setExpanded(nodeIds);
    };

    const generateIds = (existing) => {
        return existing.flatMap(({ path, children }) => {
            const childIds = generateIds(children);
            return [path, ...childIds];
        });
    }

    return (
        <>
            <BasicPage title="Download Page" icon={<Download />} loading={false}>
                {folders.length > 0 ?
                    <>
                        <Box sx={{ display: "flex", gap: "0.5rem", justifyContent: "flex-start", width: "100%", mt: 3 }}>
                            <Button
                                variant={expanded.length === 0 ? 'contained' : 'outlined'}
                                size="sm"
                                onClick={handleExpandClick}
                                startIcon={expanded.length === 0 ? <OpenInFullIcon /> : <CloseFullscreenIcon />}
                            >
                                {expanded.length === 0 ? 'expand' : 'collapse'}
                            </Button>
                        </Box>
                        <TreeView
                            aria-label="folders"
                            defaultCollapseIcon={<ExpandMoreIcon />}
                            defaultExpandIcon={<ChevronRightIcon />}
                            onNodeFocus={handleFocus}
                            sx={{ width: "100%", mb: 3, mt: 1, ml: -2 }}
                            expanded={expanded}
                            onNodeToggle={handleToggle}
                        >
                            {folders && folders.map((item, i) => <TreeItemStyled key={i} {...item} deleteHandler={deleteFolder}/>)}
                        </TreeView>
                        {images.length > 0 && <Typography variant="h4" sx={{ mb: 2, pt: 2, width: "100%", textAlign: "center" }}>Images</Typography>}
                        <Grid container spacing={1} >
                            {images && images.map((item, index) => <ImageGridItem key={index} tags={true} url={url} image={item} index={index} handleModalOpen={handleModalOpen} handleRemove={handleRemove} />)}
                        </Grid>
                    </>
                : 
                <Box sx={{ display: "flex", flexDirection: "column", gap: "0.25rem", justifyContent: "flex-start", width: "100%", mt: 3 }}>
                    <Skeleton height={40} width={120} variant="rectangular" animation="wave" />
                    <Skeleton height={40} width="100%" variant="rectangular" animation="wave" />
                    <Skeleton height={40} width="100%" variant="rectangular" animation="wave" />
                    <Skeleton height={40} width="100%" variant="rectangular" animation="wave" />
                    <Skeleton height={40} width="100%" variant="rectangular" animation="wave" />
                    <Skeleton height={40} width="100%" variant="rectangular" animation="wave" />
                    <Skeleton height={40} width="100%" variant="rectangular" animation="wave" />
                </Box>
                }
            </BasicPage>
            {selectedImage &&
                <ModalDialog open={modalOpen} setOpen={handleModalClose} >
                    <ImageModal image={selectedImage.image} index={selectedImage.index} images={images} setNext={handleModalOpen} />
                </ModalDialog>
            }
        </>
    );
}