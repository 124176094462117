import { useEffect, useState } from "react";
import { BasicPage } from "../components/BasicPage";
import { useEvents } from "../hooks/useEvents";
import { useStorage } from "../hooks/useStorage";
import { useAuth } from "../hooks/useAuth";
import { Upload } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { UploadCard } from "../components/UploadCard";
import { InputForm } from "../components/InputForm";
import { useDb } from "../hooks/useDb";
import { fileTypesString, whatTypeOfFile } from "../utils/common";

export const UploadPage = () => {
  const { images, loading, uploadImages, removeImage, setImages } = useStorage();
  const { events, currentEvent, users } = useEvents();
  const { getTagsFromDB } = useDb();
  const { user } = useAuth();
  const [tags, setTags] = useState([]);
  const [count, setCount] = useState(0);

  useEffect(() => {
    const loadTags = async () => {
      const result = await getTagsFromDB();
      setTags(result);
    }
    loadTags();
  }, []);

  useEffect(() => {
    setCount(images.length);
  }, [images])

  const updateTags = (fieldValues) => {
    const newTags = [...new Set([...tags, ...fieldValues.tags.split(',')])]
    setTags(newTags);
  }

  const handleRemove = (index) => {
    removeImage(index);
  }

  const changeUploadFiles = (e) => {
    Array.from(e.target.files, (file) => {
      setImages(prev => [...prev, {
        percent: 0,
        toUpload: file,
        localUrl: URL.createObjectURL(file),
        type: whatTypeOfFile(file)
      }])
    });
  }

  const fields = [
    {
      name: 'family',
      label: 'Select Family',
      current: user.family ? user.family : "Everyone",
      template: 'select',
      options: users,
      disabled: loading,
      validation: [{ noValidation: true }]
    },
    {
      name: 'eventName',
      label: 'Select Event',
      current: currentEvent(),
      template: 'select',
      options: events,
      disabled: loading,
      validation: [{ noValidation: true }]
    },
    {
      name: 'tags',
      label: 'Add Tags',
      current: [],
      type: 'autocomplete',
      template: 'autocomplete',
      options: tags,
      disabled: loading,
      helpText: 'Select up to 5 tags, hit enter after typing a new tag',
      message: 'Maximum 5 tags',
      validation: [{ maxLength: 6 }]
    },
    {
      name: 'files',
      type: 'file',
      disabled: loading,
      helpText: 'select up to 10 files',
      onChangeFile: (e) => changeUploadFiles(e),
      currentCount: count,
      multiple: true,
      accept: fileTypesString,
      message: 'Minimum one file, Maximum 10 files',
      validation: [{ filesCount: { max: 10, min: 1 } }],
      template: 'files'
    },
    {
      name: 'uid',
      current: user.uid,
      template: 'hidden',
      validation: [{ noValidation: true }]
    }
  ]

  return (
    <>
      <BasicPage title="Upload Page" icon={<Upload />} >
        <InputForm fields={fields} action={uploadImages} additionalAction={updateTags} buttonText="Save Images" loading={loading} />
      </BasicPage>
      <Grid container spacing={2} mt={4} mb={5}>
        {images.map((img, index) => <UploadCard image={img} index={index} handleRemove={handleRemove} key={index} loading={loading} />)}
      </Grid>
    </>
  );
}

