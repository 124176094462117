import { db } from "../firebase/firebase-config";
import { doc, setDoc, getDoc, updateDoc, addDoc, collection, getDocs, query, where, deleteDoc, writeBatch, arrayUnion } from "firebase/firestore";

export const useDb = () => {

    const imagesRef = 'images';
    const usersRef = 'users';
    const statusRef = 'statuses';
    const tagsRef = 'tags';

    const saveTagsToDB = async (data) => {
        const docRef = doc(db, tagsRef, 'all');
        const tags = await getTagsFromDB();
        const newTags = [...new Set([...tags, ...data])];
        return await setDoc(docRef, { tags: newTags });
    }

    const getTagsFromDB = async () => {
        const docRef = doc(db, tagsRef, 'all');
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            return docSnap.data().tags.sort(Intl.Collator().compare);
        } else {
            return [];
        }
    }

    const saveStatusToDB = async (data, uid) => {
        const docRef = doc(db, statusRef, uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            return await updateDoc(docRef, {
                downloaded: arrayUnion(data)
            });
        } else {
            return await setDoc(docRef, { downloaded: [data] });
        }
    }

    const getStatusesByUser = async (uid) => {
        const docSnap = await getDoc(doc(db, statusRef, uid));
        if (docSnap.exists()) {
            return docSnap.data().downloaded;
        } else {
            return [];
        }
    }

    const getImageByPropertyFromDB = async (property, propertyName) => {
        const q = query(collection(db, imagesRef), where(property, "==", propertyName));
        const querySnapshot = await getDocs(q);
        let response = null;
        querySnapshot.forEach((doc) => {
            response = doc.id
        });
        return response
    }

    const saveImageToDB = async (data) => {
        return await addDoc(collection(db, imagesRef), data);
    }

    const saveImagesToDB = async (data) => {
        const batch = writeBatch(db);
        data.forEach(d => {
            const docRef = doc(collection(db, imagesRef))
            batch.set(docRef, d);
        })
        return await batch.commit();
    }

    const updateImageInDB = async (id, data) => {
        const docRef = doc(db, imagesRef, id);
        return await updateDoc(docRef, data);
    }

    const deleteImageFromDB = async (id) => {
        return await deleteDoc(doc(db, imagesRef, id));
    }

    const getImagesByPropertyFromDB = async (property, propertyName) => {
        let dbImages = [];
        const q = property !== 'all' ?
            query(collection(db, imagesRef), where(propertyName, "==", property))
            :
            query(collection(db, imagesRef))

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            dbImages.push(Object.assign(doc.data(), { id: doc.id }));
        })

        return dbImages;
    }

    const getUserInDB = async (uid) => {
        const docRef = doc(db, usersRef, uid);
        const user = await getDoc(docRef);
        return Object.assign(user.data(), { uid: user.id });
    }

    const createUserInDB = async (uid, email) => {
        const docRef = doc(db, usersRef, uid);
        const user = await setDoc(docRef, { email: email, new: true });
        return user;
    }

    const updateUserInDB = async (uid, userData) => {
        userData.new = false;
        const docRef = doc(db, usersRef, uid);
        const user = await updateDoc(docRef, userData)
        return user
    }

    return {
        saveTagsToDB,
        getTagsFromDB,
        saveStatusToDB,
        getStatusesByUser,
        getImageByPropertyFromDB,
        saveImageToDB,
        saveImagesToDB,
        updateImageInDB,
        getImagesByPropertyFromDB,
        deleteImageFromDB,
        getUserInDB,
        createUserInDB,
        updateUserInDB,
    }
}