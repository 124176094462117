import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { storage } from "../firebase/firebase-config";
import { ref, getBlob, listAll, getMetadata } from "firebase/storage";
import { useState } from 'react';
import { useNotification } from './useNotification';
import { useDb } from './useDb';

export const useZip = () => {

    const [percent, setPercent] = useState(0);
    const [loading, setLoading] = useState(false);
    const { setNotification } = useNotification();
    const { saveStatusToDB } = useDb();

    const createZip = async (directoryPath) => {
        const zip = new JSZip();
        setLoading(prev => !prev);
        setPercent(10);
        await addFilesFromDirectoryToZip(directoryPath, zip);

        try {
            const content = await zip.generateAsync({ type: "blob" })
            const name = directoryPath.split('/').pop();
            saveAs(content, `${name}.zip`);
            setPercent(100)
            setNotification({message: 'successfully downloaded files', variant: 'success'})
            setTimeout(() => {
                setLoading(prev => !prev)
                setPercent(0)
            }, 1500)
            return true;
        } catch (error) {
            setNotification({message: 'error downloading files', variant: 'error'})
            setPercent(0)
            setLoading(prev => !prev)
            return false;
        }
    }

    const addFilesFromDirectoryToZip = async (directoryPath = "", zip) => {
        const directoryContentsRef = ref(
            storage,
            directoryPath
        );

        const directoryContents = await listAll(directoryContentsRef);

        for (const file of directoryContents.items) {
            const fileRef = ref(storage, file.fullPath);
            try {
                const fileBlob = await getBlob(fileRef)
                try {
                    const { customMetadata } = await getMetadata(fileRef);
                    const updated = customMetadata ? new Date(parseInt(customMetadata.updated)) : new Date(Date.now()); 
                    zip.file(file.fullPath, fileBlob, { date: updated });
                } catch (error) {
                    console.log(error)
                }
            } catch (error) {
                console.log(error)
            }
        }

        setPercent(prev => prev !== 75 ? 35 : 75)

        for (const folder of directoryContents.prefixes) {
            if (folder.fullPath.split('/').pop() === "thumbs") return
            await addFilesFromDirectoryToZip(folder.fullPath, zip);
        };

        setPercent(75)
    };

    return { createZip, percent, loading, setPercent }

};