import { PhotoCamera } from '@mui/icons-material';
import { FormControl, Button } from '@mui/material'
import React from 'react'
import { InputHelperText } from './InputHelperText';

export const InputFiles = ({ field, error, handleChange, value }) => {
    const { name, multiple, accept, message, disabled, helpText } = field;
    return (
        <FormControl sx={{ width: 1 }}>
            <Button
                variant="outlined"
                component="label"
                disabled={disabled}
                color={error ? 'error' : 'inherit'}
                sx={{ py: "0.8rem" }}
                size="large"
            >
                <PhotoCamera sx={{ mr: 1 }} /> Choose Files
                <input
                    hidden
                    id={name}
                    multiple={multiple}
                    accept={accept}
                    name={name}
                    value={value}
                    onChange={handleChange}
                    type="file"
                />
            </Button>
            <InputHelperText error={error} message={message} helpText={helpText} />
        </FormControl>
    )
}
