import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { ListSubheader, SvgIcon } from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

export const MenuDrawerList = ({ sections, setOpenDrawer }) => {
    const navigate = useNavigate();
    const { logout } = useAuth();

    const toggleDrawer = (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setOpenDrawer(prev => !prev);
    };

    const handleNavigate = (path) => {
        if (path) {
            navigate(path);
        }
    };

    return (
        <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={toggleDrawer}
            onKeyDown={toggleDrawer}
        >
            <List>
                {sections && sections.map((section, index) => (
                    <React.Fragment key={index}>
                        <ListSubheader key={index}>
                            {section.label}
                        </ListSubheader>

                        {section.pages.map((p, index) => (
                            <ListItem key={index} disablePadding>
                                <ListItemButton onClick={() => handleNavigate(`/${section.path}/${p.path}`)}>
                                    <ListItemIcon>
                                        <SvgIcon component={p.icon} />
                                    </ListItemIcon>
                                    <ListItemText primary={p.label} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                        <Divider />
                    </React.Fragment>
                ))}
                <ListSubheader>
                    Actions
                </ListSubheader>
                <ListItem disablePadding>
                    <ListItemButton onClick={logout}>
                        <ListItemIcon>
                            <ExitToAppIcon />
                        </ListItemIcon>
                        <ListItemText primary="Logout" />
                    </ListItemButton>
                </ListItem>

            </List>
        </Box>
    );
}