import React, { useState, useEffect } from 'react'
import { BasicPage } from '../components/BasicPage'
import { ImageModal } from '../components/ImageModal'
import { ModalDialog } from '../components/ModalDialog'
import CropOriginalIcon from '@mui/icons-material/CropOriginal';
import { useStorage } from '../hooks/useStorage'
import { Grid } from '@mui/material';
import { ImageGridItem } from '../components/ImageGridItem';
import { ImageGridSkeleton } from '../components/ImageGridSkeleton';

export const GalleryPage = () => {

    const { getImages, images, loading } = useStorage();
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    useEffect(() => {
        getImages({ name: "all", value: "all", displayLoading: true, sort: ['year'] })
    }, []);

    useEffect(() => {
        setModalOpen(selectedImage === null ? false : true)
    }, [selectedImage])

    const handleModalOpen = (index) => {
        setSelectedImage({ image: images[index], index: index })
    }

    const handleModalClose = () => {
        setSelectedImage(null)
    }

    return (
        <>
            <BasicPage title="Gallery Page" icon={<CropOriginalIcon />} loading={false} />
            <Grid container spacing={0.25}>
            {images && images.map((image, i) => <ImageGridItem key={i} wide={true} image={image} index={i} handleModalOpen={handleModalOpen} handleRemove={false} />)}
            {images.length === 0 && [...Array(30).keys()].map(s => <ImageGridSkeleton key={s} wide={true} />)}
            </Grid>
            {selectedImage &&
                <ModalDialog open={modalOpen} setOpen={handleModalClose} >
                    <ImageModal image={selectedImage.image} index={selectedImage.index} images={images} setNext={handleModalOpen} />
                </ModalDialog>
            }
        </>
    )
}
